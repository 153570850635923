import { store } from "../../redux/store";
import { ICheckout, IRoom, IRoomRate, IRoomService } from "./types";
import { TP_REDEMPTION_RATE } from "../../@types/Constants";
import { formatDateGAPI } from "../dates";

export const createEditReservationPayload = (
  checkout: ICheckout,
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  ccDetails: any
): any => {
  let isRedemptionRate;
  const roomsForRequest: Array<any> = [];
  const memberState = store.getState().member;
  const rooms = checkout.Rooms ? Object.values(checkout.Rooms) : [];
  rooms.forEach((room: IRoom) => {
    // create room rate request object
    const roomRates = []; //@TODO : consider gift card amount
    if (
      room.rate &&
      room.rate.roomRateNightly &&
      room.rate.roomRateNightly.length > 1
    ) {
      //multi nights
      room.rate.roomRateNightly.forEach((rate: IRoomRate) => {
        const amountBeforeTax = parseFloat(rate.rate);
        roomRates.push({
          amountBeforeTax: amountBeforeTax,
          effectiveDate: rate.effectiveDate,
          expireDate: rate.expireDate,
        });
      });
    } else {
      // add rates for single night.
      const amountBeforeTax = parseFloat(room.rate.subTotal);
      roomRates.push({
        amountBeforeTax: amountBeforeTax,
        effectiveDate: checkout.Start,
        expireDate: checkout.End,
      });
    }

    // create room services object
    const roomServices: Array<any> = [];
    room.services &&
      Object.values(room.services).forEach((ser: IRoomService) => {
        roomServices.push({
          description: ser.Description,
          quantity: ser.AvailedQuantity,
          serviceInventoryCode: ser.ServiceInventoryCode,
          // ServiceRPH: ser.ServiceRPH,
          total:
            (parseFloat(ser.Price) + parseFloat(ser.Tax)) * ser.AvailedQuantity,
        });
      });

    const roomObj = {
      count: {
        adult: room.adults,
        child: {
          numGuests: room.children,
          ages: room.childrenAges
            ? room.childrenAges.map((age: any) => {
                if (age === "") {
                  return 0; // Convert empty string to 0
                } else {
                  return Number(age); // Convert string to number
                }
              })
            : [],
        },
      },
      roomTypeCode: room.room.RoomCode,
      ratePlanCode: room.rate.RateCode,
      roomRates: roomRates,
      roomServices: roomServices,
    };

    roomsForRequest.push(roomObj);

    if (TP_REDEMPTION_RATE.includes(room.rate.RateCode)) {
      isRedemptionRate = true;
    }
  });
  const guestId = checkout?.GuestDetails?.id
    ? checkout?.GuestDetails?.id
    : memberState.isLoggedIn
    ? memberState.crmProfile?.memberId
    : "";
  const requestJSON = {
    hotelId: checkout?.HotelCode ? checkout.HotelCode : "",
    startDate: checkout?.Start ? formatDateGAPI(checkout.Start) : "",
    endDate: checkout?.End ? formatDateGAPI(checkout.End) : "",
    numberOfUnits: 1,
    additionalOptions: "",
    specialRequest: checkout?.SpecialRequests ? checkout.SpecialRequests : "",
    guestList: checkout?.GuestDetails
      ? [
          {
            firstName: checkout?.GuestDetails.firstName,
            lastName: checkout?.GuestDetails.lastName,
            email: checkout?.GuestDetails.email,
            phoneNumber: checkout?.GuestDetails.phoneNumber,
            membershipId: guestId,
            programId: "Travel Pass",
            isPrimary: true,
          },
        ]
      : [],
    roomsList: roomsForRequest,
    emailTemplate: isRedemptionRate ? "TPRedeem" : "Guestconf",
    promotionCode: checkout?.promotionCode ? checkout?.promotionCode : "",
    groupCode: checkout?.groupCode ? checkout?.groupCode : "",
    subSource: "Web",
    guestId: guestId,
    creditCardDetail: ccDetails,
    RedemptionItemCode: checkout?.redemptionItem?.redemptionItemCode || "",
    HotelLocation: checkout?.hotelLocation,
    Brand: checkout?.Brand,
  };

  return requestJSON;
};

export default createEditReservationPayload;
